import Head from 'next/head'
import { Form, Input, Button, Alert } from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
import * as Sentry from '@sentry/nextjs';
import React, { useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'

export default function Home() {
    const router = useRouter()
    const [recaptcha, setrecaptcha] = useState(null)
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState(false)
    async function onFinish(values) {
        try {
            if (recaptcha == null) {
                alert('reCaptcha invalido ou não verificado, verifique antes de continua.')
                return
            }
            setloading(true)
            const response = await fetch('https://api.castrafacil.com.br/painel/v1/login', {
                method: 'POST',
                body: JSON.stringify({
                    email: values.email.toLowerCase(),
                    password: values.password,
                    recaptcha: recaptcha
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
            let data = await response.json()
            if (data?.success != undefined) {
                console.log('Success:', data);
                Cookies.set('castratoken', data?.success.token)
                router.push('/board')
            } else if (data?.error != undefined) {
                seterror(data?.error)
                console.log('error server', error)
                setloading(false)
            } else {
                seterror('Não foi possivel acessar o servidor no momento, tente novamente mais tarde.')
                setloading(false)
            }
        } catch (err) {
            setloading(false)
            Sentry.captureException(err);
        }
    };

    function onFinishFailed(errorInfo) {
        console.log('Failed:', errorInfo);
    };
    function ValidaRecaptch(value) {
        console.log('value', value)
        setrecaptcha(value)
    }
    return (
        <>
            <Head>
                <title>Painel - CastraFácil</title>
                <meta name="description" content="Painel castrafacil" />
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <div className="limiter">
                <div className="container-login100">
                    <div className="divlogo">
                        <img className="logo" src="/logobranco.png" alt="logo" />
                    </div>
                    <div className="wrap-login100">
                        <Form
                            name="basic"
                            autoComplete="on"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            className="formLogin"
                        >
                            {error &&
                                <Alert
                                    showIcon
                                    message="Error"
                                    description={error}
                                    type="error"
                                    style={{ marginBottom: 10, maxWidth: 350}}
                                />
                            }
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor coloque seu email',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Senha"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor coloque sua senha',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item>
                                <ReCAPTCHA
                                    size="normal"
                                    render="explicit"
                                    hl="pt-BR"
                                    style={{ width: 100 }}
                                    sitekey="6LeOa10eAAAAAAOlseHLAiSJft56vZuRePY6HlDf"
                                    onChange={ValidaRecaptch}
                                />
                            </Form.Item>
                            <Form.Item>
                                <span style={{ cursor: 'pointer' }} onClick={() => router.push('/recovery')}>Recuperar senha?</span>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block size="large" loading={loading}>
                                    Entrar
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
}

export async function getServerSideProps(context) {
    try {
        const { req } = context
        const { castratoken } = req.cookies
        const { noredirect } = context.query
        if (castratoken && !noredirect) {
            return {
                redirect: {
                    destination: '/board',
                    permanent: false
                }
            }
        }
        return {
            props: {
                access: true
            }
        }
    } catch (err) {
        Sentry.captureException(err);
    }
}
